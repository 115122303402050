import { css } from "@emotion/react";
import {
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  Select,
  MenuItem,
  Box,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { api } from "~/utils/api";
interface Inputs {
  prompt: string;
  tags: string[];
}
export const CreatePromptForm = ({ className }: { className?: string }) => {
  const { data: tags = [], isLoading: areTagsLoading } =
    api.journalingPrompts.getTags.useQuery();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<Inputs>();
  const apiUtils = api.useContext();

  const tagValues = watch("tags");

  const { mutateAsync } = api.journalingPrompts.createPrompt.useMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await mutateAsync(
      {
        prompt: data.prompt,
        tags: data.tags,
      },
      {
        onSuccess: () => {
          reset();
          void apiUtils.journalingPrompts.getAllPrompts.invalidate();
        },
      }
    );
  };

  return (
    <Box
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      `}
    >
      <Controller
        name="prompt"
        control={control}
        defaultValue=""
        rules={{
          required: "Required",
        }}
        render={({ field }) => (
          <FormControl
            variant="filled"
            css={css`
              flex: 1 1 100%;
            `}
          >
            <InputLabel id="prompt">New Prompt</InputLabel>
            <FilledInput id="prompt" {...field} error={!!errors.prompt} />
          </FormControl>
        )}
      />
      <Controller
        name="tags"
        control={control}
        defaultValue={[]}
        rules={{
          required: "Required",
        }}
        render={({ field }) => (
          <FormControl
            css={css`
              flex: 0 0 400px;
            `}
          >
            <InputLabel id="tags">Tags</InputLabel>
            <Select
              {...field}
              labelId="tags"
              label="Tags"
              multiple
              defaultValue={[]}
              error={!!errors.tags}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={tags.find(({ id }) => id === value)?.value}
                    />
                  ))}
                </Box>
              )}
            >
              {areTagsLoading ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                tags.map((tag) => (
                  <MenuItem value={tag.id} key={tag.id}>
                    <Checkbox
                      checked={(tagValues ?? []).indexOf(tag.id) > -1}
                    />
                    <ListItemText primary={tag.value} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        variant="outlined"
        css={css`
          margin-left: auto;
        `}
      >
        Save Prompt
      </Button>
    </Box>
  );
};
