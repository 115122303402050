import { useEffect, useState } from "react";

export const useHasSignedInPreviously = () => {
  const [hasSignedInPreviously, setHasSignedInPreviously] = useState(false);
  useEffect(() => {
    setHasSignedInPreviously(
      localStorage.getItem("hasSignedInPreviously") === "true"
    );
  }, []);
  return {
    hasSignedInPreviously,
    setHasSignedInPreviously: () => {
      localStorage.setItem("hasSignedInPreviously", "true");
      setHasSignedInPreviously(true);
    },
  };
};
