import { css } from "@emotion/react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  InputLabel,
  FilledInput,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { omit } from "lodash";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { useModal, useToast } from "~/context";
import { useTracking } from "~/hooks";
import { api } from "~/utils/api";

interface Inputs {
  feedback: string;
  contactMe: boolean;
}

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const FEEDBACK_MODAL_ID = "feedback-modal";
export const useFeedbackModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], "onClose" | "onOpen">>
) =>
  useModal({
    modalId: FEEDBACK_MODAL_ID,
    onClose: props?.onClose,
    onOpen: props?.onOpen,
  });
export const FeedbackModal = () => {
  const { track } = useTracking();
  const { isOpen, closeModal } = useFeedbackModal();
  const { setToast } = useToast();
  const searchParams = useSearchParams();
  const feedbackContext = searchParams?.get("feedbackContext");
  const router = useRouter();

  const { mutateAsync: sendFeedback } = api.feedback.sendFeedback.useMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Inputs>();

  const handleClose = () => {
    reset();
    if (feedbackContext) {
      void router.replace(
        {
          pathname: router.pathname,
          query: omit(router.query, "feedbackContext"),
        },
        undefined,
        { shallow: true }
      );
    }
    closeModal();
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await sendFeedback(
      {
        feedback: data.feedback,
        contactMe: data.contactMe,
        feedbackContext: feedbackContext ?? undefined,
        pageName: router.pathname,
        pagePath: router.asPath,
      },
      {
        onSuccess: () => {
          track("feedBack_submitted", {
            context: feedbackContext ?? undefined,
            shouldContact: data.contactMe,
          });
          setToast({
            message: "Feedback Sent!",
            severity: "success",
          });
          handleClose();
        },
        onError: () => {
          setToast({
            message: "Error sending feedback. Please try again.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      css={(theme) => css`
        [role="dialog"] {
          overflow: visible;
          background-color: ${theme.palette.background.default};
        }
      `}
    >
      <DialogTitle
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 8px;
        `}
      >
        <Typography
          variant="h3"
          component="span"
          css={css`
            flex: 1 1 200px;
            font-weight: 600;
          `}
        >
          Feedback
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 24px;
        `}
      >
        <Box
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <Typography variant="body2">
            Your input is invaluable. Rest assured, it{`'`}s collected
            anonymously. We will only collect the page you are on, the time of
            the feedback, the button used to open this modal, and the feedback
            itself. If you do select {`"`}Contact Me
            {`"`} then your email will be collected as well.
          </Typography>
          <Controller
            name="feedback"
            control={control}
            defaultValue=""
            rules={{ required: "Feedback is required" }}
            render={({ field }) => (
              <FormControl
                variant="filled"
                css={css`
                  flex: 1 1 auto;
                `}
              >
                <InputLabel id="feedback">Feedback</InputLabel>
                <FilledInput
                  id="feedback"
                  {...field}
                  error={!!errors.feedback}
                  multiline
                  minRows={3}
                  css={(theme) => css`
                    &,
                    &:active,
                    &:focus,
                    &.Mui-focused,
                    &:hover {
                      background-color: ${theme.palette.background.paper};
                    }
                  `}
                  className="highlight-mask"
                />
                {!!errors.feedback?.message && (
                  <FormHelperText error>
                    {errors.feedback?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="contactMe"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label="Contact me"
              />
            )}
          />
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="outlined"
            css={css`
              margin-left: auto;
            `}
          >
            Send Feedback
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
