import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import { css } from "@emotion/react";
import { useQuill } from "react-quilljs";
import Delta, { type Op } from "quill-delta";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import type Quill from "quill";

export const QuillEditor = ({
  defaultValue,
  onChange,
  readOnly = false,
  disabled = false,
  maxCharacters = 5000,
  maxLines = 100,
  className,
  getQuill,
}: {
  defaultValue?: Op[];
  onChange?: (values: {
    numberOfCharacters: number;
    numberOfLines: number;
  }) => void;
  readOnly?: boolean;
  disabled?: boolean;
  maxCharacters?: number;
  maxLines?: number;
  className?: string;
  getQuill?: (quillObject: Quill) => void;
}) => {
  const [numberOfLines, setNumberOfLines] = useState<number>(0);
  const [numberOfCharacters, setNumberOfCharacters] = useState<number>(0);

  const { quill, quillRef } = useQuill({
    theme: "snow",
    readOnly,
    modules: {
      toolbar: readOnly
        ? null
        : [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
          ],
    },
  });

  useEffect(() => {
    if (quill) {
      getQuill?.(quill);
    }
  }, [getQuill, quill]);

  useEffect(() => {
    if (defaultValue) {
      const defaultDelta = new Delta(defaultValue);
      // @ts-expect-error - The type should be the same but quill-delta and react-quilljs are having a conflict for some reason.
      quill?.setContents(defaultDelta, "api");
    }
  }, [defaultValue, quill]);

  useEffect(() => {
    if (quill && onChange) {
      quill.on("text-change", function () {
        const newNumberOfCharacters = quill.getLength() - 1;
        const newNumberOfLines = quill.getLines().length;
        setNumberOfLines(newNumberOfLines);
        setNumberOfCharacters(newNumberOfCharacters);
        onChange({
          numberOfCharacters: newNumberOfCharacters,
          numberOfLines: newNumberOfLines,
        });
      });
    }
  }, [onChange, quill]);

  useEffect(() => {
    if (quill && !readOnly) {
      if (disabled) {
        quill.disable();
      } else {
        quill.enable();
      }
    }
  }, [disabled, quill, readOnly]);

  return (
    <Box className={className}>
      <Box
        css={(theme) => css`
          .ql-bubble .ql-tooltip {
            z-index: 100;
          }
          .ql-container.ql-container,
          .ql-toolbar.ql-toolbar {
            border: none;
            .ql-picker {
              color: #5f1b14;
            }
            .ql-picker-label:hover,
            .ql-picker-item:hover {
              color: ${theme.palette.primary.main};
            }
            .ql-picker-options {
              background-color: ${theme.palette.background.paper};
              border-color: ${theme.palette.divider}!important;
            }
            .ql-picker-item.ql-selected {
              color: ${theme.palette.primary.main};
            }
            button,
            .ql-picker-label {
              .ql-stroke {
                stroke: #5f1b14;
              }
              .ql-fill {
                fill: #5f1b14;
              }
              &:hover {
                background-color: ${theme.palette.action.hover};
                & .ql-stroke {
                  stroke: ${theme.palette.primary.main};
                }
                & .ql-fill {
                  fill: ${theme.palette.primary.main};
                }
              }
              &.ql-active {
                color: ${theme.palette.primary.main};
                .ql-stroke {
                  stroke: ${theme.palette.primary.main}!important;
                }
                .ql-fill {
                  fill: ${theme.palette.primary.main}!important;
                }
              }
            }
          }
          .ql-toolbar.ql-toolbar {
            border-bottom: 1px solid ${theme.palette.primary.dark};
            opacity: ${disabled ? 0.3 : 1};
          }
          .ql-editor {
            padding: ${readOnly ? "0" : "12px"};
            background-color: ${readOnly || disabled
              ? "transparent"
              : theme.palette.background.paper};
            &:focus {
              border-bottom: 2px solid
                ${readOnly || disabled ? "none" : theme.palette.primary.main};
            }
            min-height: ${readOnly ? "auto" : "200px"};
            &,
            p,
            li {
              font-size: 16px;
            }
          }
        `}
      >
        <div ref={quillRef} />
      </Box>
      {numberOfLines > maxLines * 0.8 && (
        <Box>
          <Typography
            variant="caption"
            css={(theme) => css`
              ${numberOfLines > maxLines
                ? css`
                    color: ${theme.palette.error.main};
                  `
                : ""}
            `}
          >
            Entry Lines: {numberOfLines} / {maxLines}
          </Typography>
        </Box>
      )}
      {numberOfCharacters > maxCharacters * 0.8 && (
        <Box css>
          <Typography
            variant="caption"
            css={(theme) => css`
              ${numberOfCharacters > maxCharacters
                ? css`
                    color: ${theme.palette.error.main};
                  `
                : ""}
            `}
          >
            Entry Length: {numberOfCharacters} / {maxCharacters}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
