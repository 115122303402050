import { Snackbar, type AlertProps, Alert } from '@mui/material';
import { createContext, useContext, useState } from 'react';

interface Toast {
  severity: AlertProps['severity'];
  message: React.ReactNode;
}

export interface ToastProviderArgs {
  children: React.ReactNode;
}

export interface ToastContextValues {
  setToast: (toast: Toast) => void;
}

const ToastContext = createContext<ToastContextValues | Record<string, never>>(
  {},
);

// TODO: look into potentially supporting stacks of toasts. This will only show the latest.
export const ToastProvider = ({ children }: ToastProviderArgs): JSX.Element => {
  const [currentToast, setCurrentToast] = useState<Toast | null>(null);

  return (
    <ToastContext.Provider
      value={{
        setToast: setCurrentToast,
      }}
    >
      <Snackbar
        open={!!currentToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={() => {
          setCurrentToast(null);
        }}
      >
        <Alert
          severity={currentToast?.severity}
          sx={{ width: 'fit-content' }}
          elevation={4}
        >
          {currentToast?.message}
        </Alert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
