import { H } from "highlight.run";
import { usePlausible } from "next-plausible";

type Events = {
  createEntry_started: { preMood: number };
  createEntry_saved: Record<string, never>;
  createEntry_addedPostMood: { postMood: number };
  createEntry_finsihed: { community?: string };

  entry_sentToCommunity: { community: string };
  entry_setIsShared: { isShared: boolean };
  entry_savedThinkDeeperResponse: Record<string, never>;
  entry_generatedQuestions: Record<string, never>;
  entry_generatedAffirmations: Record<string, never>;
  entry_generatedGoals: Record<string, never>;
  entry_generatedArt: Record<string, never>;
  entry_generatedUplifts: Record<string, never>;
  entry_generatedStory: Record<string, never>;
  entry_generatedSongList: Record<string, never>;

  feedBack_opened: { context?: string };
  feedBack_submitted: { context?: string; shouldContact: boolean };

  user_requestDeletion: Record<string, never>;
  user_requestUserData: Record<string, never>;
};

export const useTracking = () => {
  const plausible = usePlausible();
  const track = (
    eventName: keyof Events,
    eventProperties?: Events[keyof Events]
  ) => {
    console.log(`[tracking] ${eventName}`, eventProperties);
    H.track(eventName, eventProperties);
    // Make sure to add any new eventnames and properties to https://plausible.io/redpandajournaling.com/settings/goals and https://plausible.io/redpandajournaling.com/settings/properties
    plausible(eventName, {
      props: eventProperties,
    });
  };
  return { track };
};
