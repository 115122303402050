import { Inter } from "next/font/google";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import type { Theme as MaterialUITheme } from "@mui/material/styles";

export const inter = Inter({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Helvetica", "Arial", "sans-serif"],
});

// See [here](https://duncanleung.com/accessing-material-ui-theme-object-emotion-styled-component-css-prop/#emotion-css-string-styles---access-theme-object)
// for how to access theme object with the emotion css prop.

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#F1695C",
      },
      secondary: {
        main: "#8B5CF6",
      },
      divider: "#52525B",
      error: {
        main: "#CA8A04",
      },
      warning: {
        main: "#F97316",
      },
      info: {
        main: "#9A8772",
      },
      success: {
        main: "#88FFCC",
      },
      text: {
        primary: "#131313",
      },
      background: {
        default: "#fdeceb",
        paper: "#FCDAD6",
      },
      action: {
        hover: "#fcdad6",
      },
    },
    typography: {
      h1: {
        fontSize: "2rem",
        fontWeight: 500,
        lineHeight: 1.26,
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 500,
        lineHeight: 1.25,
      },
      h3: {
        fontSize: "1.3rem",
        fontWeight: 500,
        lineHeight: 1.25,
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.25,
      },
      h5: {
        fontSize: "0.9rem",
        fontWeight: 500,
        lineHeight: 1.25,
      },
      h6: {
        fontSize: "0.9rem",
        lineHeight: 1.25,
      },
      body2: {
        lineHeight: 1.25,
      },
      body1: {
        lineHeight: 1.25,
      },
      button: {
        fontSize: "0.875rem",
        lineHeight: 1.25,
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: "rgba(0, 0, 0, 0.8)",
            },
            fontSize: "1.2rem",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
              cursor: "pointer",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
  }),
  { factor: 2 }
);

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module "@emotion/react" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends MaterialUITheme {}
}
