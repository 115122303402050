"use client";

import { useAuth } from "@clerk/nextjs";
import { HighlightInit } from "@highlight-run/next/client";
import { useEffect } from "react";
import { env } from "~/env.mjs";
import { api } from "~/utils/api";
import { isProd } from "~/utils/env-checks";

export const HighlightInitWrapper = () => {
  const { isSignedIn } = useAuth();
  const { data: user } = api.user.getCurrentUser.useQuery(undefined, {
    enabled: !!isSignedIn,
  });

  useEffect(() => {
    if (user) {
      window.localStorage.setItem(
        "allowSessionRecording",
        JSON.stringify(user.allowSessionRecording)
      );
    }
  }, [user]);

  const allowSessionRecording =
    user?.allowSessionRecording ??
    (typeof window !== "undefined" &&
      window.localStorage.getItem("allowSessionRecording") === "true");

  console.log("allowSessionRecording", allowSessionRecording);
  console.log("disableSessionRecording", !isProd || !allowSessionRecording);

  return (
    <HighlightInit
      projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
      environment={env.NEXT_PUBLIC_APP_ENV}
      excludedHostnames={["localhost"]}
      tracingOrigins
      serviceName={`frontend-${isProd ? "prod" : "test"}`}
      disableSessionRecording={!isProd || !allowSessionRecording}
      networkRecording={{
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [],
      }}
      version={env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}
      storageMode="sessionStorage"
      consoleMethodsToRecord={["log", "warn", "error"]}
    />
  );
};
