import { css } from "@emotion/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { QuillEditor } from "./QuillEditor";
import { api } from "~/utils/api";
import Delta, { type Op } from "quill-delta";
import {
  type IconContainerProps,
  Rating,
  Button,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Chip,
  TextField,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Alert,
  Link,
} from "@mui/material";
import {
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedAltIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { startCase } from "lodash";
import { PromptSelector } from "./PromptSelector";
import type Quill from "quill";
import { useRouter } from "next/router";
import { usePrevious, useTracking } from "~/hooks";
import Image from "next/image";
import { useFeedbackModal } from "./FeedbackModal";

const StyledRating = styled(Rating)(() => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    opacity: 0.8,
    color: "#5f1b14",
  },
  "& .MuiRating-icon": {
    opacity: 0.8,
    color: "#5f1b14",
  },
}));

export const feelingsScale: Record<
  string,
  {
    icon: React.ReactElement;
    label: string;
  }
> = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
    label: "Pretty Bad",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
    label: "Bad",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize="large" />,
    label: "Meh",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
    label: "Good",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
    label: "Great",
  },
};
function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return (
    <Box
      component="span"
      css={(theme) => css`
        svg {
          color: ${theme.palette.primary.main};
        }
      `}
      {...other}
    >
      {feelingsScale[value]?.icon}
    </Box>
  );
}

export const CreateJournalEntry = ({
  defaultValue,
  onComplete,
}: {
  defaultValue?: Op[];
  onComplete?: () => void;
}) => {
  const { track } = useTracking();
  const router = useRouter();
  const { openModal: openFeedbackModal } = useFeedbackModal();
  const {
    mutateAsync: connectEntryToCommunity,
    isLoading: isConnectingToCommunity,
    data: connectedCommunity,
  } = api.community.connectEntryToCommunity.useMutation();

  const {
    mutate: createJournalEntry,
    isLoading: isCreatingEntry,
    data: journalEntryFromCreate,
  } = api.journals.createJournalEntry.useMutation();
  const {
    mutate: updateJournalEntry,
    isLoading: isUpdatingEntry,
    data: journalEntryFromUpdate,
  } = api.journals.updateJournalEntry.useMutation();
  const {
    mutateAsync: updateJournalEntryTitle,
    isLoading: isUpdatingEntryTitle,
  } = api.journals.updateJournalEntryTitle.useMutation();
  const {
    data: communityRecommendations,
    mutate: generateCommunityRecommendations,
    isLoading: isGeneratingCommunityRecommendations,
  } = api.journals.getSuggestedCommunitiesForEntry.useMutation();
  const {
    data: detectedEntryEmotions,
    mutate: generateDetectedEntryEmotions,
    isLoading: isGeneratingDetectedEntryEmotions,
  } = api.journals.detectEntryEmotions.useMutation();
  const { mutate: generateQuestions } =
    api.journals.generateEntryQuestions.useMutation();

  const journalEntry = journalEntryFromUpdate ?? journalEntryFromCreate;
  const context = api.useContext();

  const [currentPrompt, setCurrentPrompt] = useState<
    | {
        id: string;
        value: string;
      }
    | undefined
  >(undefined);

  const [numberOfCharacters, setNumberOfCharacters] = useState<number>(0);
  const [numberOfLines, setNumberOfLines] = useState<number>(0);

  const [journalEntryId, setJournalEntryId] = useState<string>("");
  const [journalEntryContentsId, setJournalEntryContentsId] = useState<
    string | undefined
  >("");

  const [hoverPreMood, setHoverPreMood] = useState(-1);
  const [preMood, setPreMood] = useState<number | null>(null);
  const previousPreMood = usePrevious(preMood);
  const [hoverPostMood, setHoverPostMood] = useState(-1);
  const [postMood, setPostMood] = useState<number | null>(null);
  const previousPostMood = usePrevious(postMood);
  const [title, setTitle] = useState<string>("");
  const [communityToSendTo, setCommunityToSendTo] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const quill = useRef<Quill>();

  const handleReset = () => {
    if (onComplete) {
      onComplete();
    }
    // @ts-expect-error - The type should be the same but quill-delta and react-quilljs are having a conflict for some reason.
    quill.current?.setContents(new Delta());
    setTitle("");
    setCommunityToSendTo(null);
    setNumberOfCharacters(0);
    setNumberOfLines(0);
    setJournalEntryId("");
    setJournalEntryContentsId("");
    setPreMood(null);
    setPostMood(null);
    setHasSubmitted(false);
    void context.journals.getUserEntries.invalidate();
  };

  const handleSetQuill = useMemo(
    () => (quillObject: Quill) => (quill.current = quillObject),
    []
  );

  useEffect(() => {
    if (!previousPreMood && !!preMood) {
      track("createEntry_started", {
        preMood,
      });
    }
  }, [preMood, previousPreMood, track]);
  useEffect(() => {
    if (!previousPostMood && !!postMood) {
      track("createEntry_addedPostMood", {
        postMood,
      });
    }
  }, [postMood, previousPostMood, track]);

  const journalRef = useRef<HTMLDivElement>(null);
  const scrollToJournal = () => {
    const position = journalRef.current?.getBoundingClientRect().top;
    if (position) {
      window.scrollTo({
        top: position + window.scrollY - 55,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (preMood && !previousPreMood) {
      scrollToJournal();
    }
  }, [preMood, previousPreMood]);

  const creatingJournalRef = useRef<HTMLDivElement>(null);
  const scrollToCreatingJournal = () => {
    const position = creatingJournalRef.current?.getBoundingClientRect().top;
    if (position) {
      window.scrollTo({
        top: position + window.scrollY,
        behavior: "smooth",
      });
    }
  };
  const hasScrolledToCreatingJournal = useRef(false);
  useEffect(() => {
    if (isCreatingEntry && !hasScrolledToCreatingJournal.current) {
      scrollToCreatingJournal();
      hasScrolledToCreatingJournal.current = true;
    }
  }, [hasSubmitted, isCreatingEntry]);

  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <Box
        css={css`
          ${preMood
            ? `
            opacity: 0.5; 
          `
            : ""}
        `}
      >
        <Typography
          variant="h3"
          css={css`
            font-weight: 600;
          `}
        >
          How are you feeling today?
        </Typography>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;
          `}
        >
          <StyledRating
            disabled={isCreatingEntry || !!journalEntryId}
            value={preMood}
            name="highlight-selected-only"
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => feelingsScale[value]?.label ?? ""}
            highlightSelectedOnly
            onChangeActive={(_e, newHover) => {
              setHoverPreMood(newHover);
            }}
            onChange={(_e, newValue) => {
              setPreMood(newValue);
            }}
          />
          <Typography>
            {(hoverPreMood !== -1 || !!preMood) && "I'm feeling "}
            {feelingsScale[(hoverPreMood !== -1 ? hoverPreMood : preMood) ?? 0]
              ?.label ?? " "}
          </Typography>
        </Box>
      </Box>

      {preMood && (
        <Box
          ref={journalRef}
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              ${hasSubmitted
                ? `
                  opacity: 0.5; 
                `
                : ""}
            `}
          >
            <Divider />

            <PromptSelector
              disabled={!!journalEntryId}
              onPromptChange={(prompt) => setCurrentPrompt(prompt)}
              css={css`
                padding-top: 8px;
              `}
            />

            <QuillEditor
              getQuill={handleSetQuill}
              disabled={
                isCreatingEntry || isUpdatingEntry || !!journalEntryContentsId
              }
              defaultValue={defaultValue}
              onChange={(value) => {
                setNumberOfCharacters(value.numberOfCharacters);
                setNumberOfLines(value.numberOfLines);
              }}
              className="highlight-mask"
            />
            <Button
              disabled={
                isCreatingEntry ||
                isUpdatingEntry ||
                numberOfCharacters < 20 ||
                numberOfCharacters > 5000 ||
                numberOfLines > 100 ||
                hasSubmitted
              }
              type="button"
              onClick={() => {
                if (!quill.current) {
                  throw new Error("Quill is not defined");
                }
                const contents = quill.current.getContents().ops as Op[];
                const rawText = quill.current?.getText();
                createJournalEntry(
                  {
                    promptId: currentPrompt?.id,
                    contents,
                    rawText,
                    title,
                    preMood,
                  },
                  {
                    onSuccess: (data) => {
                      track("createEntry_saved");
                      setTitle(data.aiTitle);
                      setJournalEntryContentsId(data.journalEntryContentsId);
                      setJournalEntryId(data.journalEntryId);
                      generateDetectedEntryEmotions({
                        journalEntryId: data.journalEntryId,
                      });
                      generateCommunityRecommendations({
                        journalEntryId: data.journalEntryId,
                      });
                      generateQuestions({
                        journalEntryId: data.journalEntryId,
                      });
                    },
                  }
                );

                setHasSubmitted(true);
              }}
              variant="outlined"
              size="large"
              css={css`
                max-width: 200px;
                opacity: ${hasSubmitted ? 0.5 : 1};
              `}
            >
              Save & Continue
            </Button>
          </Box>
          {hasSubmitted && (
            <Box
              ref={creatingJournalRef}
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-top: 24px;
              `}
            >
              <Divider
                css={css`
                  margin-bottom: 40px;
                `}
              />

              {isCreatingEntry ? (
                <Box
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 4px;
                  `}
                >
                  <Typography variant="body2">
                    Saving. This may take up to a minute. Please do not close or
                    navigate away.
                  </Typography>
                  <LinearProgress />
                </Box>
              ) : (
                <>
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 24px;
                      align-items: flex-start;
                    `}
                  >
                    <Box
                      css={css`
                        flex: 0 0 96px;
                      `}
                    >
                      <Image src="/jordi-1.svg" alt="" width={96} height={96} />
                      <Typography variant="caption">
                        Jordi the <br />
                        Red Panda
                      </Typography>
                    </Box>{" "}
                    <Box
                      css={css`
                        flex: 1 1 200px;
                        display: flex;
                        flex-direction: column;
                      `}
                    >
                      <Typography variant="body1">
                        {journalEntry?.aiMessage}
                      </Typography>

                      <Typography
                        variant="caption"
                        css={css`
                          margin-left: auto;
                        `}
                      >
                        <Link
                          onClick={() => {
                            void router.replace(
                              {
                                pathname: "/",
                                query: {
                                  entryId: journalEntryId,
                                  feedbackContext: "report-entry-ai-response",
                                },
                              },
                              undefined,
                              { shallow: true }
                            );
                            track("feedBack_opened", {
                              context: "report-entry-ai-response",
                            });
                            openFeedbackModal();
                          }}
                        >
                          Report as inappropriate
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="h6">Detected Emotions:</Typography>
                  {isGeneratingDetectedEntryEmotions ? (
                    <Box
                      css={css`
                        min-height: 32px;
                      `}
                    >
                      <LinearProgress
                        css={css`
                          margin: 8px 0;
                        `}
                      />
                    </Box>
                  ) : (
                    <Box
                      css={css`
                        display: flex;
                        gap: 8px;
                        flex-wrap: wrap;
                        min-height: 32px;
                      `}
                    >
                      {detectedEntryEmotions?.aiDetectedEmotionsCSV
                        ?.split(",")
                        .filter(Boolean).length
                        ? detectedEntryEmotions?.aiDetectedEmotionsCSV
                            .split(",")
                            // AI returns the string null if no emotions are detected.
                            .filter((emotion) => emotion !== "null")
                            .map((emotion, i) => (
                              <Chip
                                component="span"
                                key={i}
                                label={startCase(emotion)}
                                size="small"
                                variant="outlined"
                              />
                            ))
                        : "None"}
                    </Box>
                  )}
                  <Typography
                    variant="h3"
                    css={css`
                      font-weight: 600;
                      padding-top: 16px;
                    `}
                  >
                    How are you feeling now?
                  </Typography>
                  <Box
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 8px;
                      align-items: center;
                    `}
                  >
                    <StyledRating
                      disabled={isUpdatingEntry}
                      value={postMood ?? preMood}
                      name="highlight-selected-only"
                      IconContainerComponent={IconContainer}
                      getLabelText={(value: number) =>
                        feelingsScale[value]?.label ?? ""
                      }
                      highlightSelectedOnly
                      onChangeActive={(_e, newHover) => {
                        setHoverPostMood(newHover);
                      }}
                      onChange={(_event, newValue) => {
                        setPostMood(newValue);
                        updateJournalEntry({
                          journalEntryId,
                          postMood: newValue! ?? preMood,
                        });
                      }}
                    />
                    <Typography>
                      {`I'm ${
                        preMood === postMood ||
                        preMood === hoverPostMood ||
                        (!postMood && hoverPostMood === -1)
                          ? "still "
                          : ""
                      }feeling `}
                      {feelingsScale[
                        (hoverPostMood !== -1
                          ? hoverPostMood
                          : postMood ?? preMood) ?? 0
                      ]?.label ?? " "}
                    </Typography>
                  </Box>
                  <Box
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 4px;
                    `}
                  >
                    <Typography
                      variant="h3"
                      css={css`
                        font-weight: 600;
                        padding-top: 16px;
                      `}
                    >
                      Entry Title
                    </Typography>
                    <TextField
                      label=""
                      variant="filled"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      autoComplete="off"
                      disabled={
                        isCreatingEntry ||
                        isUpdatingEntry ||
                        isConnectingToCommunity
                      }
                      inputProps={{
                        className: "highlight-mask",
                      }}
                      css={(theme) => css`
                        max-width: 600px;
                        &,
                        .MuiInputBase-root {
                          background-color: transparent;
                        }
                        input {
                          padding: 8px;
                          background-color: ${hasSubmitted
                            ? "transparent"
                            : theme.palette.background.paper};
                        }
                      `}
                    />
                  </Box>

                  <Box>
                    <Typography
                      variant="h3"
                      css={css`
                        font-weight: 600;
                        padding-top: 16px;
                      `}
                    >
                      Send to a Community (Optional)
                    </Typography>
                    {isGeneratingCommunityRecommendations ? (
                      <LinearProgress
                        css={css`
                          margin: 8px 0;
                        `}
                      />
                    ) : connectedCommunity ? (
                      <Typography>Sent to community!</Typography>
                    ) : isGeneratingCommunityRecommendations ? (
                      <CircularProgress />
                    ) : (
                      // TODO: Add this back in once we have more users
                      // ) : (communityRecommendations?.entryCount ?? 0) < 3 ? (
                      //   <Box>
                      //     <Typography>
                      //       Keep writing those entries. Once you have written 3
                      //       entries we will start suggesting communities.
                      //     </Typography>
                      //   </Box>
                      <Box>
                        <Typography
                          css={css`
                            opacity: 0.7;
                          `}
                        >
                          Suggested for this Entry:
                        </Typography>{" "}
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            padding: 0 8px;
                          `}
                        >
                          {communityRecommendations?.entry.communitySuggestions.map(
                            (community) => (
                              <FormControlLabel
                                key={community.id}
                                onChange={() => {
                                  if (communityToSendTo?.id === community.id) {
                                    setCommunityToSendTo(null);
                                  } else {
                                    setCommunityToSendTo(community);
                                  }
                                }}
                                css={css`
                                  .MuiCheckbox-root {
                                    padding: 2px;
                                  }
                                `}
                                control={
                                  <Checkbox
                                    checked={
                                      communityToSendTo?.id === community.id
                                    }
                                  />
                                }
                                label={`${community.name}${
                                  communityRecommendations?.user.communityMemberships.some(
                                    ({ community: userCommunity }) =>
                                      userCommunity.id === community.id
                                  )
                                    ? " (Joined)"
                                    : ""
                                }`}
                              />
                            )
                          )}
                        </Box>
                        <Typography
                          css={css`
                            opacity: 0.7;
                            padding-top: 4px;
                          `}
                        >
                          Joined:
                        </Typography>{" "}
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            padding: 0 8px;
                          `}
                        >
                          {communityRecommendations?.user.communityMemberships
                            .filter(
                              ({ community }) =>
                                !communityRecommendations.entry.communitySuggestions.some(
                                  (communitySuggestion) =>
                                    communitySuggestion.id === community.id
                                )
                            )
                            .map(({ community }) => (
                              <FormControlLabel
                                key={community.id}
                                onChange={() => {
                                  if (communityToSendTo?.id === community.id) {
                                    setCommunityToSendTo(null);
                                  } else {
                                    setCommunityToSendTo(community);
                                  }
                                }}
                                css={css`
                                  .MuiCheckbox-root {
                                    padding: 2px;
                                  }
                                `}
                                control={
                                  <Checkbox
                                    checked={
                                      communityToSendTo?.id === community.id
                                    }
                                  />
                                }
                                label={community.name}
                              />
                            ))}
                        </Box>
                      </Box>
                    )}
                    {!!communityToSendTo && (
                      <Alert
                        severity="info"
                        variant="outlined"
                        css={css`
                          margin-top: 8px;
                        `}
                      >
                        <Typography variant="body2">
                          When sharing with a community people will see your Red
                          Panda number. For your safety, make sure you don{`'`}t
                          expose any information that might allow users to
                          identify you. For example don{`'`}t include any
                          addresses or locations and use fake names for people
                          you mention in your entry.
                        </Typography>
                      </Alert>
                    )}
                  </Box>

                  {/* TODO: add action here to save the title to the entry, I should probably move the title out of contents. */}
                  <Button
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      await updateJournalEntryTitle({
                        title,
                        journalEntryId,
                      });
                      if (communityToSendTo) {
                        await connectEntryToCommunity({
                          journalEntryId,
                          communityId: communityToSendTo?.id,
                        });
                      }
                      track("createEntry_finsihed", {
                        community: communityToSendTo?.name,
                      });
                      handleReset();
                      void router.push({
                        pathname: "/entry/[id]",
                        query: { id: journalEntryId },
                      });
                    }}
                    disabled={
                      isUpdatingEntry ||
                      isConnectingToCommunity ||
                      isUpdatingEntryTitle
                    }
                    variant="outlined"
                    size="large"
                    fullWidth
                    css={css`
                      margin-top: 16px;
                    `}
                  >
                    {isConnectingToCommunity
                      ? "Sending.."
                      : `Finish${communityToSendTo ? " & Send" : ""}`}
                  </Button>
                  {!title.length && (
                    <Typography>
                      An entry title is required to share it with the community.
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
