import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { InstallMobile as InstallMobileIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
export const InstallButton = () => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
  const [installPrompt, setInstallPrompt] = useState<any>(null);
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setInstallPrompt(event);
    });
    window.addEventListener("appinstalled", () => {
      setIsInstalled(true);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window?.navigator?.standalone) {
      setIsInstalled(true);
    }
  }, []);

  if (isInstalled) {
    return null;
  }
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => {
          if (installPrompt) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            installPrompt.prompt();
          } else {
            void router.push("/how-to-install");
          }
        }}
      >
        <ListItemIcon>
          <InstallMobileIcon />
        </ListItemIcon>
        <ListItemText primary="Install App" />
      </ListItemButton>
    </ListItem>
  );
};
