"use client";

import { useUser } from "@clerk/nextjs";
import { useEffect } from "react";
import { useHasSignedInPreviously } from "~/hooks";

export const SetHasSignedInFlag = () => {
  const { isSignedIn } = useUser();
  const { hasSignedInPreviously, setHasSignedInPreviously } =
    useHasSignedInPreviously();
  useEffect(() => {
    if (isSignedIn && !hasSignedInPreviously) {
      setHasSignedInPreviously();
    }
  }, [hasSignedInPreviously, isSignedIn, setHasSignedInPreviously]);
  return null;
};
