import { css } from "@emotion/react";
import {
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  Box,
} from "@mui/material";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { api } from "~/utils/api";
interface Inputs {
  tagValue: string;
}
export const CreateTagForm = ({ className }: { className?: string }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Inputs>();
  const utils = api.useContext();

  const { mutateAsync } = api.journalingPrompts.createTag.useMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await mutateAsync(
      {
        value: data.tagValue,
      },
      {
        onSuccess: () => {
          reset();
          void utils.journalingPrompts.getTags.invalidate();
          void utils.journalingPrompts.getAllTags.invalidate();
        },
      }
    );
  };

  return (
    <Box
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      `}
      className={className}
    >
      <Controller
        name="tagValue"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <FormControl
            variant="filled"
            css={css`
              flex: 1 1 auto;
            `}
          >
            <InputLabel id="tagValue">New Tag</InputLabel>
            <FilledInput id="tagValue" {...field} error={!!errors.tagValue} />
          </FormControl>
        )}
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        variant="outlined"
        css={css`
          margin-left: auto;
        `}
      >
        Save Tag
      </Button>
    </Box>
  );
};
