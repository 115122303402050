import { get } from "lodash";
import { createContext, useContext, useState } from "react";

export interface ModalProviderArgs {
  children: React.ReactNode;
}

export interface ModalContextValues {
  getModalStatus: (modalId: string) => boolean;
  openModal: (modalId: string) => void;
  closeModal: (modalId: string) => void;
}

const ModalContext = createContext<ModalContextValues | Record<string, never>>(
  {}
);

export const ModalProvider = ({ children }: ModalProviderArgs): JSX.Element => {
  const [modals, setModals] = useState<Record<string, boolean>>({});

  const handleOpenModal = (modalId: string) => {
    setModals({
      ...modals,
      [modalId]: true,
    });
  };
  const handleCloseModal = (modalId: string) => {
    setModals({
      ...modals,
      [modalId]: false,
    });
  };

  return (
    <ModalContext.Provider
      value={{
        getModalStatus: (modalId: string) => get(modals, modalId, false),
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = ({
  modalId,
  onClose,
  onOpen,
}: {
  modalId: string;
  onClose?: () => void;
  onOpen?: () => void;
}) => {
  const { getModalStatus, openModal, closeModal } = useContext(ModalContext);
  return {
    isOpen: getModalStatus(modalId),
    openModal: () => {
      if (onOpen) {
        onOpen();
      }
      openModal(modalId);
    },
    closeModal: () => {
      if (onClose) {
        onClose();
      }
      closeModal(modalId);
    },
  };
};
