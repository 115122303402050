import { type AppProps, type AppType } from "next/app";
import { api } from "~/utils/api";
// https://github.com/4lejandrito/next-plausible
import PlausibleProvider from "next-plausible";
// https://www.highlight.io/docs/getting-started/client-sdk/nextjs
import { ErrorBoundary } from "@highlight-run/react";
import { ClerkProvider, useUser } from "@clerk/nextjs";
import { env } from "~/env.mjs";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, type EmotionCache } from "@emotion/react";
import createEmotionCache from "~/utils/createEmotionCache";
import Head from "next/head";
import { theme } from "~/styles/theme";
import { ModalProvider, ToastProvider } from "~/context";
import { AgreementsModal, useAgreementsModal } from "~/components";
import { usePathname } from "next/navigation";
import { isProd } from "~/utils/env-checks";
import { HighlightInitWrapper } from "~/components/HighlightInitWrapper";
import { SetHasSignedInFlag } from "~/components/SetHasSignedInFlag";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const AgreementsCheck = () => {
  const pathname = usePathname();
  const { isSignedIn } = useUser();
  const { openModal, isOpen } = useAgreementsModal();
  const { data } =
    api.agreements.getHasCurrentUserAcceptedLatestAgreements.useQuery(
      undefined,
      {
        enabled: !!isSignedIn,
      }
    );
  if (
    data?.hasAcceptedLatestAgreements === false &&
    !isOpen &&
    isSignedIn &&
    ![
      "/community-guidelines",
      "/privacy",
      "/terms",
      "/beta",
      "/cookies",
      "/how-to-install",
    ].includes(pathname ?? "")
  ) {
    openModal();
  }
  return <AgreementsModal />;
};

const MyApp: AppType = ({ Component, pageProps, emotionCache }: MyAppProps) => {
  return (
    <ClerkProvider {...pageProps}>
      <PlausibleProvider
        domain={`${
          env.NEXT_PUBLIC_APP_ENV === "staging" ? "test." : ""
        }redpandajournaling.com`}
        taggedEvents
        enabled={isProd || env.NEXT_PUBLIC_APP_ENV === "staging"}
      >
        <HighlightInitWrapper />
        <ErrorBoundary>
          <CacheProvider value={emotionCache ?? clientSideEmotionCache}>
            <Head>
              <meta
                name="viewport"
                content="initial-scale=1, width=device-width"
              />
              <link rel="manifest" href="/manifest.webmanifest" />
            </Head>
            <MuiThemeProvider theme={theme}>
              <EmotionThemeProvider theme={theme}>
                <ToastProvider>
                  <ModalProvider>
                    <Component {...pageProps} />
                    <AgreementsCheck />
                  </ModalProvider>
                </ToastProvider>
              </EmotionThemeProvider>
              <CssBaseline />
            </MuiThemeProvider>
          </CacheProvider>
        </ErrorBoundary>
      </PlausibleProvider>
      <SetHasSignedInFlag />
    </ClerkProvider>
  );
};

export default api.withTRPC(MyApp);
