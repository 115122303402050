import { css } from "@emotion/react";
import { useAuth } from "@clerk/nextjs";
import {
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Link,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  QuestionMark as QuestionMarkIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { useState } from "react";
import { api } from "~/utils/api";
import {
  PromptSettingsModal,
  usePrompSettingsModal,
} from "./PromptSettingsModal";
export const PromptSelector = ({
  className,
  onPromptChange,
  disabled,
}: {
  className?: string;
  disabled?: boolean;
  onPromptChange: (prompt?: { id: string; value: string }) => void;
}) => {
  const { isSignedIn } = useAuth();
  const { openModal: openPromptSettingsModal } = usePrompSettingsModal();

  const { data: selectedPromptTags, isInitialLoading: isUserLoading } =
    api.journalingPrompts.getCurrentUserTags.useQuery(undefined, {
      enabled: !!isSignedIn,
    });

  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const handleSetCurrentPromptIndex = (newIndex: number) => {
    setCurrentPromptIndex(newIndex);
    onPromptChange(prompts[newIndex]);
  };
  const { data: prompts = [], isLoading: arePromptsLoading } =
    api.journalingPrompts.getPrompts.useQuery(
      {
        tags: isSignedIn
          ? selectedPromptTags?.map(({ id }) => id) ?? []
          : undefined,
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!selectedPromptTags || !isSignedIn,
        onSuccess: (prompts) => {
          if (prompts.length > 0) {
            const newIndex = Math.floor(Math.random() * prompts.length);
            setCurrentPromptIndex(newIndex);
            onPromptChange(prompts[newIndex]);
          }
        },
      }
    );

  const isPageLoading = (!!isSignedIn && isUserLoading) || arePromptsLoading;

  if (isPageLoading) {
    return (
      <Box className={className}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Box className={className}>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          `}
        >
          <Typography
            variant="h3"
            css={css`
              font-weight: 600;
            `}
          >
            Your personalized prompt:
          </Typography>

          {isSignedIn && (
            <Typography
              variant="body2"
              css={css`
                margin-left: auto;
                text-align: right;
                opacity: 0.7;
              `}
            >
              <Link onClick={openPromptSettingsModal}>Customize</Link>
            </Typography>
          )}
        </Box>
        <Box
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            padding-top: 4px;
            min-height: 80px;
          `}
        >
          <IconButton
            disabled={disabled}
            onClick={() => {
              if (currentPromptIndex <= 0) {
                handleSetCurrentPromptIndex(prompts.length - 1);
              } else {
                handleSetCurrentPromptIndex(currentPromptIndex - 1);
              }
            }}
            css={css`
              color: #5f1b14;
            `}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <Typography
            variant="body1"
            css={css`
              font-size: 18px;
              opacity: ${prompts[currentPromptIndex]?.value ? 1 : 0.5};
            `}
          >
            {prompts[currentPromptIndex]?.value ?? "Lets go freestyle!"}
          </Typography>

          <IconButton
            disabled={disabled}
            onClick={() => {
              if (currentPromptIndex + 1 === prompts.length) {
                handleSetCurrentPromptIndex(0);
              } else {
                handleSetCurrentPromptIndex(currentPromptIndex + 1);
              }
            }}
            css={css`
              margin-left: auto;
              color: #5f1b14;
            `}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              const newRandomIndex = Math.floor(Math.random() * prompts.length);
              if (newRandomIndex !== currentPromptIndex) {
                handleSetCurrentPromptIndex(newRandomIndex);
              } else if (newRandomIndex > 0) {
                handleSetCurrentPromptIndex(newRandomIndex - 1);
              } else {
                handleSetCurrentPromptIndex(newRandomIndex + 1);
              }
            }}
            disabled={prompts.length <= 1 || disabled}
            css={css`
              color: #5f1b14;
            `}
          >
            <QuestionMarkIcon
              css={css`
                font-size: 16px;
              `}
            />
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              handleSetCurrentPromptIndex(-1);
            }}
            css={css`
              color: #5f1b14;
            `}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <PromptSettingsModal />
    </>
  );
};
