import { css } from "@emotion/react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useModal } from "~/context";
import { SavePrompPreferences } from "./SavePromptPreferences";

const PROMPT_SETTINGS_MODAL_ID = "prompt-settings-modal";
export const usePrompSettingsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], "onClose" | "onOpen">>
) =>
  useModal({
    modalId: PROMPT_SETTINGS_MODAL_ID,
    onClose: props?.onClose,
    onOpen: props?.onOpen,
  });
export const PromptSettingsModal = () => {
  const { isOpen, closeModal } = usePrompSettingsModal();

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      css={(theme) => css`
        [role="dialog"] {
          overflow: visible;
          background-color: ${theme.palette.background.default}};
        }
      `}
    >
      <DialogTitle>
        <Typography
          variant="h3"
          component="span"
          css={css`
            flex: 1 1 200px;
            font-weight: 600;
          `}
        >
          Prompt Preferences
        </Typography>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px 32px 24px;
        `}
      >
        <SavePrompPreferences onSuccess={() => closeModal()} />
      </DialogContent>
    </Dialog>
  );
};
