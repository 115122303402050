import Head from "next/head";
import { css } from "@emotion/react";
import { useAuth, useUser } from "@clerk/nextjs";
import Image from "next/image";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Toolbar,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  Add as AddIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  HistoryEdu as HistoryEduIcon,
  EmojiPeople as EmojiPeopleIcon,
  AssignmentOutlined as AssignmentOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  LoginOutlined as LoginOutlinedIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import { useHasSignedInPreviously, useTracking } from "~/hooks";
import { usePathname } from "next/navigation";
import { FeedbackModal, useFeedbackModal } from "./FeedbackModal";
import { useRouter } from "next/router";
import { useSignUpModal } from "./SignUpModal";
import { useSignInModal } from "./SignInModal";
import { InstallButton } from "./InstallButton";
import path from "path";

const drawerWidth = 240;

export const PageWrapper = ({
  title = "Red Panda Journaling",
  description = "Journaling your way to a better life.",
  children,
  hideSideNav = false,
}: {
  title?: string;
  description?: string;
  children: React.ReactNode;
  hideSideNav?: boolean;
}) => {
  const { track } = useTracking();
  const router = useRouter();
  const pathname = usePathname();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { hasSignedInPreviously } = useHasSignedInPreviously();
  const { isSignedIn, signOut } = useAuth();
  const { user: clerkUser } = useUser();
  const { openModal: openFeedbackModal, isOpen: isFeedbackModelOpen } =
    useFeedbackModal();
  const isAdmin = clerkUser?.organizationMemberships.some(
    ({ organization, role }) =>
      organization.slug === "the-red-panda-lords" && role === "admin"
  );
  const { openModal: openSignUpModal } = useSignUpModal();
  const { openModal: openSignInModal } = useSignInModal();

  // This is a bit hacky since we can't lock users on clerk's free or hobby plans.
  useEffect(() => {
    if (clerkUser?.publicMetadata.isMarkedForDeletion) {
      void signOut();
    }
  }, [clerkUser?.publicMetadata.isMarkedForDeletion, signOut]);

  const drawer = (
    <Box
      css={(theme) => css`
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        background-color: ${theme.palette.background.default};
        .MuiListItemButton-root,
        .MuiSvgIcon-root {
          color: #5f1b14;
        }
        .Mui-selected {
          color: ${theme.palette.primary.main};
          .MuiSvgIcon-root {
            color: ${theme.palette.primary.main};
          }
        }
      `}
    >
      <List
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
        `}
      >
        <ListItem disablePadding>
          <ListItemButton
            href={`/`}
            selected={
              (pathname === "/" ||
                (!!pathname?.includes("/entry") &&
                  !pathname?.includes("/community") &&
                  !pathname?.includes("/admin"))) &&
              pathname !== "/entry/new"
            }
          >
            <ListItemIcon>
              <HistoryEduIcon />
            </ListItemIcon>
            <ListItemText primary="Journaling" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            href={`/communities`}
            selected={
              pathname?.includes("/communities") &&
              !pathname?.includes("/admin")
            }
            disabled={!isSignedIn}
          >
            <ListItemIcon>
              <EmojiPeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Community" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              track("feedBack_opened", { context: "navigation" });
              openFeedbackModal();
            }}
            disabled={!isSignedIn}
            selected={isFeedbackModelOpen}
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItemButton>
        </ListItem>
        {isAdmin && (
          <ListItem disablePadding>
            <ListItemButton
              href={`/admin`}
              selected={pathname?.includes("admin")}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin tools" />
            </ListItemButton>
          </ListItem>
        )}
        <Box
          css={css`
            flex: 1 1 auto;
          `}
        />
        <InstallButton />
        <ListItem disablePadding>
          <ListItemButton
            disabled={!isSignedIn}
            href={`/settings`}
            selected={pathname?.includes("settings")}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          {!isSignedIn ? (
            hasSignedInPreviously ? (
              <ListItemButton onClick={openSignInModal}>
                <ListItemIcon>
                  <LoginOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItemButton>
            ) : (
              <ListItemButton onClick={openSignUpModal}>
                <ListItemIcon>
                  <LoginOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Up" />
              </ListItemButton>
            )
          ) : (
            <ListItemButton
              disabled={!isSignedIn}
              onClick={() => void signOut()}
            >
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Box>
  );

  const ToolBarSection = (props: { className?: string }) => (
    <Toolbar
      sx={{
        p: { sm: "0 16px" },
      }}
      css={css`
        height: 56px;
        width: 1000px;
        max-width: 100vw;
        margin: 0 auto;
      `}
      {...props}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1 1 auto;
        `}
      >
        <Link href="/">
          <Image
            src="/logo.png"
            alt="Red Panda Journaling Logo"
            width={25}
            height={25}
          />
        </Link>
        <Typography
          variant="h6"
          css={css`
            font-weight: 600;
            color: #5f1b14;
          `}
        >
          Red Panda Journaling
        </Typography>
      </Box>
      {!hideSideNav && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ ml: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </Toolbar>
  );

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/logo-32x32.jpeg" sizes="32x32" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Box
        sx={{ display: "flex" }}
        css={css`
          background-color: #fdeceb;
        `}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "#FDECEB",
            color: "#000000",
            borderBottom: "1px solid #fcdad6",
            height: "56px",
          }}
        >
          <ToolBarSection />
        </AppBar>
        {!hideSideNav && (
          <Drawer
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        )}
        <Box
          component="main"
          css={css`
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            flex-grow: 1;
            height: "56px";
          `}
        >
          <ToolBarSection
            // Toolbar here is used as spacer
            css={css`
              opacity: 0;
            `}
          />

          <Box
            css={css`
              width: 1000px;
              max-width: 100vw;
              margin: 0 auto;
            `}
          >
            {children}

            {isSignedIn && (
              <Box
                sx={{
                  paddingBottom: {
                    xs: "16px",
                    md: "0px",
                  },
                  height: {
                    xs: "60px",
                    md: "40px",
                  },
                }}
                css={css`
                  position: fixed;
                  bottom: 0px;
                  right: 50%;
                  transform: translateX(50%);
                  z-index: 10;
                  background-color: #fff;
                  height: 60px;
                  width: 100%;
                  max-width: 600px;
                  display: flex;
                  border-top: 1px solid #fcdad6;
                `}
              >
                <Button
                  href={`/entry/new`}
                  variant="contained"
                  size="large"
                  sx={{
                    height: {
                      xs: "70px",
                      md: "50px",
                    },
                  }}
                  css={css`
                    position: absolute;
                    right: 50%;
                    top: 50%;
                    transform: translate(50%, calc(-50% - 5px));
                    padding: 12px;
                    min-width: 0;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    z-index: 10;
                  `}
                >
                  <AddIcon />
                </Button>
                <Button
                  href={`/`}
                  css={css`
                    flex: 1 1 100px;
                    border-radius: 0;
                    border-bottom: 6px solid
                      ${pathname === "/"
                        ? `
                        #f1695c;
                      `
                        : "#fff"};
                  `}
                >
                  My Entries
                </Button>
                <Box
                  css={css`
                    width: 40px;
                  `}
                />
                <Button
                  href={`/communities`}
                  css={css`
                    flex: 1 1 100px;
                    border-radius: 0;
                    border-bottom: 6px solid
                      ${pathname?.includes("/communities") &&
                      !pathname?.includes("/admin")
                        ? `
                        #f1695c;
                      `
                        : "#fff"};
                  `}
                >
                  Communities
                </Button>
              </Box>
            )}
          </Box>
          <Box
            css={css`
              width: 100%;
              padding: 8px;
              padding-top: 32px;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              margin-top: auto;
              opacity: 0.6;
            `}
          >
            <Typography
              variant="caption"
              css={css`
                flex: 1 1 100%;
                text-align: center;
              `}
            >
              Red Panda Journaling is still in Beta.{" "}
              <Link href="/beta">Learn more.</Link>
            </Typography>

            <Typography variant="caption">
              <Link href="/terms">Terms of Service</Link>
              {" | "}
              <Link href="/privacy">Privacy Policy</Link>
              {" | "}
              <Link href="/community-guidelines">Community Guidelines</Link>
            </Typography>
            <Divider
              css={css`
                flex: 1 1 100%;
                margin: 8px 0;
              `}
            />
            <Typography
              variant="caption"
              css={css`
                flex: 1 1 100%;
                text-align: center;
              `}
            >
              Disclaimer: We are not licensed therapists or mental health
              professionals. Any advice or information provided on this website
              is for informational and educational purposes only and should not
              be considered a substitute for professional mental health advice,
              diagnosis, or treatment. If you or someone you know is struggling
              with mental health issues, we strongly recommend seeking guidance
              from a qualified mental health professional. Reliance on any
              content or advice provided here is solely at your own risk, and we
              do not assume any liability for the consequences of your actions.
            </Typography>
          </Box>
        </Box>
      </Box>
      <FeedbackModal />
    </>
  );
};
