import { Button, Typography } from "@mui/material";
import { type AgreementTypes, type Agreements } from "@prisma/client";
import { QuillEditor } from "./QuillEditor";
import { type Op } from "quill-delta";
import { useMemo, useRef } from "react";
import { api } from "~/utils/api";
import type Quill from "quill";

interface Agreement extends AgreementTypes {
  agreements: Agreements[];
}

export const AgreementEditor = ({ agreement }: { agreement: Agreement }) => {
  const latestAgreement = agreement.agreements[0];
  const quill = useRef<Quill>();
  const handleSetQuill = useMemo(
    () => (quillObject: Quill) => (quill.current = quillObject),
    []
  );

  const { mutate: createNewVersion, isLoading: isUpdating } =
    api.agreements.saveNewAgreementVersion.useMutation();

  return (
    <>
      <Typography variant="h3">{agreement.name}</Typography>
      <QuillEditor
        defaultValue={(latestAgreement?.contents ?? []) as Op[]}
        disabled={isUpdating}
        getQuill={handleSetQuill}
      />
      <Button
        onClick={() => {
          const isConfirmed = window.confirm(
            "Have you checked the new version for spelling errors? Did you confirm with the universe that your acts are acceptable?"
          );
          if (isConfirmed) {
            createNewVersion({
              typeId: agreement.id,
              contents: quill.current?.getContents().ops as Op[],
            });
          }
        }}
      >
        Save new version
      </Button>
    </>
  );
};
