import { SignUp, useUser } from "@clerk/nextjs";
import { css } from "@emotion/react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useModal } from "~/context";

const SIGN_UP_MODAL_ID = "sign-up-modal";
export const useSignUpModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], "onClose" | "onOpen">>
) =>
  useModal({
    modalId: SIGN_UP_MODAL_ID,
    onClose: props?.onClose,
    onOpen: props?.onOpen,
  });
export const SignUpModal = () => {
  const { isSignedIn } = useUser();
  const { isOpen, closeModal } = useSignUpModal();
  const router = useRouter();

  const handleCloseModal = useCallback(async () => {
    await router.replace("/", undefined, { shallow: true });
    closeModal();
  }, [closeModal, router]);

  useEffect(() => {
    if (isSignedIn && isOpen) {
      void handleCloseModal();
    }
  }, [handleCloseModal, isOpen, isSignedIn]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => void handleCloseModal()}
      fullWidth
      maxWidth="sm"
      css={css`
        [role="dialog"] {
          overflow: visible;
        }
      `}
      className="highlight-block"
    >
      <SignUp
        routing="virtual"
        signInUrl="/?modal=sign-in"
        appearance={{
          elements: {
            rootBox: css`
              display: flex;
              width: 100%;
              justify-content: center;
            `,
            card: css`
              border: none;
              box-shadow: none;
              width: 100%;
              margin: 0;
              padding-bottom: 8px;
            `,
          },
        }}
      />
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px 32px 24px;
        `}
      >
        <Typography variant="body2">
          By creating an account you agree to our{" "}
          <Link href="/terms">Terms of Service</Link>,{" "}
          <Link href="/privacy">Privacy Policy</Link>,{" "}
          <Link href="/community-guidelines">Community Guidelines</Link>, and{" "}
          <Link href="/beta">Beta Agreement</Link>.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
