import { css } from "@emotion/react";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, type SubmitHandler, useForm } from "react-hook-form";
import { useToast } from "~/context";
import { api } from "~/utils/api";

interface Inputs {
  tags: string[];
}

export const SavePrompPreferences = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const apiUtils = api.useContext();

  const { data: tags = [], isInitialLoading: areTagsLoading } =
    api.journalingPrompts.getTags.useQuery();
  const { data: selectedPromptTags, isInitialLoading: isUserLoading } =
    api.journalingPrompts.getCurrentUserTags.useQuery();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<Inputs>();
  const tagValues = watch("tags");
  const { setToast } = useToast();

  const { mutateAsync: updateTagPreferences } =
    api.user.updatePromptTagPreferences.useMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateTagPreferences(
      {
        tags: data.tags,
      },
      {
        onSuccess: (user) => {
          if (onSuccess) {
            onSuccess();
          }
          setToast({
            severity: "success",
            message: "Successfully saved prompt preferences.",
          });
          apiUtils.user.getCurrentUser.setData(undefined, user);
        },
      }
    );
  };

  const isLoading = isUserLoading || areTagsLoading;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding: 16px 0;
      `}
    >
      <Controller
        name="tags"
        control={control}
        defaultValue={selectedPromptTags?.map(({ id }) => id) ?? []}
        rules={{
          required: "Please select at least one tag.",
        }}
        render={({ field }) => (
          <FormControl
            css={css`
              flex: 1 0 400px;
              max-width: 100%;
            `}
          >
            <InputLabel id="tags">Tags</InputLabel>
            <Select
              {...field}
              labelId="tags"
              label="Tags"
              multiple
              defaultValue={[]}
              error={!!errors.tags}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={tags.find(({ id }) => id === value)?.value}
                    />
                  ))}
                </Box>
              )}
            >
              {areTagsLoading ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                tags.map((tag) => (
                  <MenuItem value={tag.id} key={tag.id}>
                    <Checkbox
                      checked={(tagValues ?? []).indexOf(tag.id) > -1}
                    />
                    <ListItemText primary={tag.value} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        variant="outlined"
        css={css`
          margin-left: auto;
        `}
      >
        Save Tags
      </Button>
    </Box>
  );
};
